import { StylesClasses, createStyles } from '@utils/styles'

const styles = createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    gap: '16px',
    position: 'relative',
    padding: '100px 0 112px 0',
    backgroundColor: 'custom.lightGrey',
  },
  header: {
    zIndex: '1',
    position: 'fixed',
    borderTopLeftRadius: { md: '8px' },
    top: '0',
    height: '84px',
    width: '100%',
    display: 'flex',
    justifyContent: { xs: 'space-between', md: 'unset' },
    gap: { md: '340px' },
    alignItems: 'center',
    backgroundColor: 'common.white',
    padding: '24px',
    boxSizing: 'border-box',
    boxShadow: '0px -1px 19.6px 0px #00000017',
  },
  heading: {
    color: 'custom.black',
    fontSize: '24px',
    fontWeight: '700',
  },
  closeIcon: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  itemHeading: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'custom.black',
  },
  linksWrapper: {
    width: '100%',
    height: '75px',
    padding: '10px 24px',
    // backgroundColor: 'common.white',
  },
  addMoreLink: {
    color: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    cursor: 'pointer',
  },
  ctaWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px 20px',
    backgroundColor: 'common.white',
    width: { xs: '100%', md: '504px' },
    height: '96px',
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: '0',
    boxShadow: '0px -1px 19.6px 0px #00000017',
    borderRadius: { md: '0px 0px 0px 8px' },
  },
  ctaButton: {
    float: 'right',
    fontSize: { md: '20px' },
    fontWeight: '600',
    height: { xs: '50px', md: '64px' },
  },
  fetchedAddressContainer: {
    backgroundColor: 'custom.lightGrey',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  checkboxContainer: {
    padding: '10px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
    backgroundColor: 'common.white',
  },
  checkbox: {
    display: 'flex',
    flex: '1',
    backgroundColor: 'common.white',
    marginRight: '10px',
  },
  checkboxLabel: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'custom.lightSilver',
  },
  optionIcon: {
    cursor: 'pointer',
    color: 'custom.grey',
    marginRight: '20px',
  },
  skeleton: { width: '90%', margin: '0px auto', height: '95px' },
})
export type StylesClassNames = StylesClasses<typeof styles>
export default styles
